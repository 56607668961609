import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';

export default function Uses() {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "vscode" }) {
        id
        publicURL
        name
      }
    }
  `);
  return (
    <Layout>
      <h1>What am I using?</h1>
      <p>
        This page contains information about all the things I use in development
        including configs, extensions, editors, shells, etc.
      </p>
      <h2 id="editor">Editor</h2>
      <p>
        I'm using{' '}
        <a
          href="https://code.visualstudio.com/"
          target="_blank"
          rel="noreferrer"
        >
          Visual Studio Code
        </a>{' '}
        as my primary editor. There are minor cases when I use other editors
        such as Vim or Nano (usually when I need to edit some system config file
        in terminal). I use a lot of VSCode extensions and I tweak it a lot.
      </p>
      <img style={{width: "100%"}} src={data.file.publicURL} alt="Visual Studio Code" />
      <p>
        My favorite font is{' '}
        <a
          href="https://github.com/tonsky/FiraCode"
          target="_blank"
          rel="noreferrer"
        >
          Fira Code
        </a>{' '}
        with font ligatures enabled. To enhance visibility in file explorer, I
        use the{' '}
        <a href="https://marketplace.visualstudio.com/items?itemName=PKief.material-icon-theme">
          Material Icon Theme
        </a>{' '}
        extension which shows the appropriate icon next to the file based on the
        file extension. Dark+ color theme is my preferred, it's also the default
        theme.
      </p>
      <h2 id="editor-config">Editor config</h2>
      <p>
        I'm used to settings in JSON format instead of UI. My tweaks start with
        disabling several VSCode features, such as a minimap, breadcrumbs,
        activity bar, and open editors. Next, I moved the sidebar on the
        opposite (right) side. I like the autosave feature, as I don't need to
        tap <kbd>Cmd</kbd> + <kbd>S</kbd> every time instead, I set autoSave on{' '}
        <b>afterDelay</b> with a delay of <b>400ms</b>.
      </p>
      <h2 id="editor-extensions">Editor extensions</h2>
      <p>
        The first extension which every developer should have is{' '}
        <a href="https://prettier.io/" target="_blank" rel="noreferrer">
          Prettier
        </a>
        . It's a code formatter that helps you which good code formatting, so
        you don't need to spend time with aligning code, etc. I've turned on
        formatting on the save feature, so after every save, the code is
        formatted appropriately.{' '}
      </p>
      <p>
        With the code formatting, the second must-have extension is{' '}
        <a href="https://eslint.org/" target="_blank" rel="noreferrer">
          ESLint
        </a>{' '}
        linting library. VSCode is so intelligent that it underlines your code
        in red when you made an error.
      </p>
      <p>
        Since writing JavaScript includes writing many blocks, you can lose the
        track of bracket pairs, I've solved it using the{' '}
        <a
          href="https://marketplace.visualstudio.com/items?itemName=CoenraadS.bracket-pair-colorizer"
          target="_blank"
          rel="noreferrer"
        >
          Bracket Pair Colorizer
        </a>{' '}
        extension. This extension does really what it says in the name of it,
        colorize bracket pairs, so you can see more quickly which bracket
        corresponds to which.
      </p>
      <p>
        Other useful extensions that I use are{' '}
        <a
          href="https://marketplace.visualstudio.com/items?itemName=aaron-bond.better-comments"
          target="_blank"
          rel="noreferrer"
        >
          Better Comments
        </a>
        ,{' '}
        <a
          href="https://marketplace.visualstudio.com/items?itemName=msjsdiag.debugger-for-chrome"
          target="_blank"
          rel="noreferrer"
        >
          Debugger for Chrome
        </a>
        ,{' '}
        <a
          href="https://marketplace.visualstudio.com/items?itemName=wix.vscode-import-cost"
          target="_blank"
          rel="noreferrer"
        >
          Import Cost
        </a>
        , and{' '}
        <a href="https://marketplace.visualstudio.com/items?itemName=MS-vsliveshare.vsliveshare-pack">
          Live Share
        </a>
        .
      </p>
      <h2 id="browser">Browser</h2>
      <p>
        Currently, I'm using Google Chrome as my default browser with several
        extensions, such as React DevTools, Redux DevTools, JSON Formatter,
        Grammarly, Enpass, daily.dev
      </p>
      <h2 id="terminal">Terminal</h2>
      <p>
        I used to use preinstalled GNOME Terminal app on Ubuntu and Fedora, but
        now I'm using iTerm. Furthermore, I used bash as my default shell, but
        since 2020 I'm using ZSH with Oh My Zsh framework.
      </p>
      <h2 id="notebook">Notebook</h2>
      <p>
        Since summer 2020 I'm using the 2020 MacBook Pro 13" 2.3 GHz quad-core
        Intel Core i7 with 16 GB of RAM. OS is macOS Big Sur 11.1. Before it, I
        was using Asus ZenBook UX301LA with Intel Core i7 (Fedora Silverblue).
      </p>
      <h2 id="other">Other HW</h2>
      <p>
        As I love music and hate twisted cables, I have bought QCY T1 headphones
        and I think they are really great. I'm using an i-tec hub to connect the
        other peripherals such as external monitors, Ethernet, etc.
      </p>
    </Layout>
  );
}
